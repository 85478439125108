<template>
  <page-container :title="pageTitle" :breadcrumb="breadcrumb">
    <div class="box-container" style="margin-top: 10px;"  >
      <div class="box-container-inner">
        <div class="simple-query">
          <a-form-model ref="queryForm" :model="queryParams" layout="inline" class="query-form">
            <a-form-model-item label="公司/项目" prop="monitorpointname" style="width: 24%">
              <a-cascader :field-names="{ label: 'userdepname', value: 'userdepid', children: 'children' }" :options="userInfo.usertype!='0'?deptOptions.filter(item => item.deptype=='1'):deptOptionsAll.filter(item => item.deptype=='1')" v-model="userdepidCascaderSelected" :load-data="userInfo.usertype!='0'?deptOnLoadChildren:deptOnLoadChildrenAll" placeholder="请选择公司/项目" :change-on-select="true" :allow-clear="false"></a-cascader>
            </a-form-model-item>
            <a-form-model-item label="计划维修单号" prop="order_num" style="width: 24%">
              <a-input v-model="queryParams.order_num"></a-input>
            </a-form-model-item>
            <a-form-model-item label="查询时段" prop="queryInstallDate" style="width: 24%">
              <a-range-picker v-model="queryInstallDate" :default-value="[moment(`${queryParams.starttime.substring(0,10)}`, 'YYYY-MM-DD'), moment(`${queryParams.endtime.substring(0,10)}`, 'YYYY-MM-DD')]"></a-range-picker>
            </a-form-model-item>
            <div class="query-btns" style="float: right;width: 21%">
              <a-button @click="resetQueryParams">重置</a-button>
              <a-button type="primary" class="after-btn" @click="getTableData(true)">查询</a-button>
              <a-button type="link" @click="showAdvanced=!showAdvanced">展开<a-icon :type="showAdvanced?'up':'down'"></a-icon></a-button>
            </div>
            <div v-show="showAdvanced" class="advanced-query">
              <a-form-model-item label="计划来源" prop="order_source" style="width: 24%">
                <a-select v-model="queryParams.order_source">
                  <a-select-option value="转自报修">转自报修</a-select-option>
                  <a-select-option value="自排计划">自排计划</a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item label="维修类型" prop="repair_matter_type" style="width: 24%">
                <a-select v-model="queryParams.repair_matter_type">
                  <a-select-option value="公区维修">公区维修</a-select-option>
                  <a-select-option value="专属维修">专属维修</a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item label="设备编号" prop="device_num" style="width: 24%">
                <a-input v-model="queryParams.device_num"></a-input>
              </a-form-model-item>
              <a-form-model-item label="设备名称" prop="device_name" style="width: 24%">
                <a-input v-model="queryParams.device_name"></a-input>
              </a-form-model-item>
              <a-form-model-item label="当前状态" prop="order_status" style="width: 24%">
                <a-select v-model="queryParams.order_status">
                  <a-select-option value="未开始">未开始</a-select-option>
                  <a-select-option value="未结束">未结束</a-select-option>
                  <a-select-option value="已完成">已完成</a-select-option>
                </a-select>
              </a-form-model-item>
            </div>
          </a-form-model>
        </div>
      </div>
    </div>
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner">
        <div class="table-header">
          <h3 class="table-title">计划执行情况</h3>
          <div class="table-btns">
<!--            <a-button @click="exportModal" style="margin-right: 10px"><a-icon type="export"></a-icon>批量导出</a-button>-->
            <a-icon type="redo" class="after-btn table-refresh" @click="getTableData()"></a-icon>
          </div>
        </div>
        <a-table :customRow="customRow" :columns="tableColumns" :data-source="tableData" row-key="order_id" :loading="tableLoading" :scroll="{ x: 2400}" :row-class-name="(record, index) => (index%2==0?'table-row-even':'table-row-single')" style="margin-top: 20px;" :pagination="false">
          <span slot="create_time" slot-scope="value">
            <a-tooltip :title="value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''">
              <span>{{value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''}}</span>
            </a-tooltip>
          </span>
          <span slot="need_start_time" slot-scope="value">
            <a-tooltip :title="value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''">
              <span>{{value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''}}</span>
            </a-tooltip>
          </span>
          <span slot="need_finish_time" slot-scope="value">
            <a-tooltip :title="value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''">
              <span>{{value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''}}</span>
            </a-tooltip>
          </span>
          <span slot="actual_start_time" slot-scope="value">
            <a-tooltip :title="value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''">
              <span>{{value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''}}</span>
            </a-tooltip>
          </span>
          <span slot="actual_end_time" slot-scope="value">
            <a-tooltip :title="value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''">
              <span>{{value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''}}</span>
            </a-tooltip>
          </span>
          <span slot="start_deviation" slot-scope="value">
            <span>{{timeHandle(value)}}</span>
          </span>
          <span slot="end_deviation" slot-scope="value">
            <span>{{timeHandle(value)}}</span>
          </span>
          <span slot="process_node" slot-scope="value">
            <span>{{value===91?"已完成":value===10||value===20?"未开始":"跟进中"}}</span>
          </span>
          <span slot="operation" slot-scope="value, record">
            <a-dropdown>
              <a class="ant-dropdown-link" @click="e => e.preventDefault()">
                <a-icon type="menu"/>
              </a>
              <a-menu slot="overlay" @click="operationClick">
                <a-menu-item :key="'detail-'+record.order_id">详情</a-menu-item>
                <a-menu-item :key="'edit-'+record.order_id">修改</a-menu-item>
                <a-menu-item :key="'delete-'+record.order_id">删除</a-menu-item>
              </a-menu>
            </a-dropdown>
          </span>
        </a-table>
        <a-pagination v-model="currentPage" :total="pagination.total" :page-size="pagination.pageSize" show-size-changer @change="pageChange" @showSizeChange="onShowSizeChange" :show-total="(total, range) => `共${total}条`" style="margin-top: 20px; text-align: right;">
          <template slot="buildOptionText" slot-scope="props">
            <span>{{ props.value }}条/页</span>
          </template>
        </a-pagination>
      </div>
    </div>
    <detail-modal :visible.sync="repairTaskVisible" :show-type="repairTaskShowType" :detailData="repairTaskDetailData" @get-operation-result="getTaskTableData"></detail-modal>
  </page-container>
</template>
<script>
import moment from "moment";
import {mapState} from "vuex";
import pagination from "@/mixins/pagination";
import {getCache, getItemFromArrayByKey, getTimeRange} from "U/index";
import deptselect from "@/mixins/deptselect";
import {
  deleteRepairSchedule, getRepairsOrderListByCondition,
  getRepairScheduleContrastListByCondition,
} from "A/repairsreport";
import {resultCode} from "@/json/orderSteps";
import DetailModal from "V/facilityAndEquipment/maintenanceManagement/repair/plan/DetailModal";
export default {
  mixins: [deptselect,pagination],
  components: {
    DetailModal,
  },
  data() {
    return {
      modalVisible: false,
      taskModalVisible: false,
      moment,
      resultCode,
      tableData: [],
      tableLoading: false,
      showAdvanced: false,
      queryInstallDate:null,
      queryParams:{
        monitorpointnum:'',
        device_num:'',
        device_name:'',
        order_num:'',
        order_source:'',
        order_status:'',
        repair_matter_type:'',
        starttime:null,
        endtime:null,
      },
      tableColumns:[
        {
          title: '项目名称',
          dataIndex: 'monitorpointname',
          key: 'monitorpointname',
          ellipsis: true,
        },
        {
          title: '计划维修编号',
          dataIndex: 'order_num',
          key: 'order_num',
          ellipsis: true,
        },
        {
          title: '计划来源',
          dataIndex: 'order_source',
          key: 'order_source',
          ellipsis: true,
        },
        {
          title: '报修单号',
          dataIndex: 'parent_order_num',
          key: 'parent_order_num',
          ellipsis: true,
        },
        {
          title: '维修类型',
          dataIndex: 'repair_matter_type',
          key: 'repair_matter_type',
          ellipsis: true,
        },
        {
          title: '设备编号',
          dataIndex: 'device_num',
          key: 'device_num',
          ellipsis: true,
        },
        {
          title: '设备名称',
          dataIndex: 'device_name',
          key: 'device_name',
          ellipsis: true,
        },
        {
          title: '维修内容',
          dataIndex: 'content',
          key: 'content',
          ellipsis: true,
        },
        {
          title: '维修形式',
          dataIndex: 'handle_form',
          key: 'handle_form',
          ellipsis: true,
        },
        {
          title: '维修单位',
          dataIndex: 'handle_unit',
          key: 'handle_unit',
          ellipsis: true,
        },
        {
          title: '计划开始时间',
          dataIndex: 'need_start_time',
          key: 'need_start_time',
          ellipsis: true,
          width: 140,
          scopedSlots: { customRender: 'need_start_time' },
        },
        {
          title: '计划结束时间',
          dataIndex: 'need_finish_time',
          key: 'need_finish_time',
          ellipsis: true,
          width: 140,
          scopedSlots: { customRender: 'need_finish_time' },
        },
        {
          title: '实际开始时间',
          dataIndex: 'actual_start_time',
          key: 'actual_start_time',
          ellipsis: true,
          width: 140,
          scopedSlots: { customRender: 'actual_start_time' },
        },
        {
          title: '实际结束时间',
          dataIndex: 'actual_end_time',
          key: 'actual_end_time',
          ellipsis: true,
          width: 140,
          scopedSlots: { customRender: 'actual_end_time' },
        },
        {
          title: '开始偏差',
          dataIndex: 'start_deviation',
          key: 'start_deviation',
          ellipsis: true,
          scopedSlots: { customRender: 'start_deviation' },
        },
        {
          title: '结束偏差',
          dataIndex: 'end_deviation',
          key: 'end_deviation',
          ellipsis: true,
          scopedSlots: { customRender: 'end_deviation' },
        },
        {
          title: '当前状态',
          dataIndex: 'process_node',
          key: 'process_node',
          ellipsis: true,
          scopedSlots: { customRender: 'process_node' },
        },
      ],
      userdepidCascaderSelected: [],
      repairTaskVisible: false,
      repairTaskShowType: '',
      repairTaskDetailData: {},
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo
    }),
    pageTitle() {
      return "计划执行看板"
    },
    breadcrumb() {
      const pages = [{name:"设施设备管理",path:""}]
      pages.push({name:"维修管理",path:""})
      pages.push({name:this.pageTitle,path:""})
      return pages
    },
  },
  watch:{
    userdepidCascaderSelected(val) {
      if(val && val.length) {
        this.queryParams.userdepsid = val[val.length-1];
      }else {
        this.queryParams.userdepsid = '';
      }
    },
    queryInstallDate(val) {
      let {start, end} = getTimeRange(val);
      this.queryParams.starttime = start;
      this.queryParams.endtime = end;
    },
  },
  created() {
    let time = new Date();
    let monthNum = moment(time).format("YYYY-MM").slice(5);
    this.queryParams.starttime = moment(time).month(monthNum - 1).date(1).startOf("month").format("YYYYMMDD");
    this.queryParams.endtime = moment(time).month(monthNum - 1).date(1).endOf("month").format("YYYYMMDD");
    this.getTableData()
    this.initDeptOptionsAll();
    let dictionary = getCache('dictionary', true);
    if(dictionary) {
      this.deviceCodeList = dictionary.deviceCodeList
      this.equipmentParamsList = dictionary.equipmentParamsList
      this.equipmentCategoryList = dictionary.equipmentCategoryList
      this.equipmentChildCategoryList = dictionary.equipmentChildCategoryList
      this.equipmentStatusList = dictionary.equipmentStatusList
    }
  },
  methods:{
    openTaskModal(record,order_date){
      this.getTaskTableData(true,record.equipment_name,record.equipment_num,order_date,record.monitorpointnum)
      this.taskModalVisible=true
    },
    getTableData(firstPage) {
      if(firstPage) {
        this.currentPage = 1;
      }
      this.tableLoading = true;
      let params = {
        ...this.queryParams,
        pageno: this.currentPage,
        pagesize: this.pagination.pageSize
      };
      getRepairScheduleContrastListByCondition(params).then(res => {
        this.tableLoading = false;
        if(res && res.returncode == '0') {
          this.tableData = res.item;
          this.pagination.total = res.count;
        }
      }).catch(err => {
        this.tableLoading = false;
      })
    },
    resetQueryParams() {
      this.userdepidCascaderSelected=[]
      this.$refs.queryForm.resetFields();
      let dictionary = getCache('dictionary', true);
      if(dictionary) {
      }
    },
    operationClick({key}){
      let arr = key.split('-');
      let type = arr[0];
      let order_id = arr[1];
      let record = getItemFromArrayByKey(this.tableData, 'order_id', order_id);
      if(type == 'delete') {
        this.deleteConfirm(order_id, record)
      }else{
        this.showModal(type,record)
      }
    },
    showModal(type,record){
      if(type=='detail'){
        this.modalDetailData=record
        this.modalShowType=type
        this.modalVisible=true
      }else if(type=='edit'){
        this.modalDetailData=record
        this.modalShowType=type
        this.modalVisible=true
      }
    },
    addRecord(){
      this.modalVisible=true
      this.modalShowType='add'
    },
    rowSelectChange(selectedRowKeys, selectedRows) {
      this.batchrowids = selectedRowKeys.join('|');
    },
    getTaskTableData(firstPage,equipment_name,equipment_num,order_date,monitorpointnum){
      if(firstPage) {
        this.currentPage1 = 1;
      }
      this.tableLoading = true;
      let params = {
        order_date:order_date,
        equipment_num:equipment_num,
        equipmentname:equipment_name,
        monitorpointnum:monitorpointnum,
        pageno: this.currentPage1,
        pagesize: this.pagination1.pageSize
      };
      getRepairsOrderListByCondition(params).then(res => {
        this.tableLoading = false;
        if(res && res.returncode == '0') {
          this.taskTableData = res.item;
          this.pagination1.total = res.count;
        }
      }).catch(err => {
        this.tableLoading = false;
      })
    },
    customRow(record, index){
      return {
        on: { // 事件
          click: () => {
            this.repairTaskDetailData=record
            this.repairTaskShowType='detail'
            this.repairTaskVisible=true
          },
        },
      }
    },
    timeHandle(time){
      let returnTime;
      let day;
      let hour;
      let minutes;
      let seconds;
      let positiveAndNegative=time<0?'neg':'pos'
      time=Math.abs(time)
      if(time>86400) {
        day = Math.floor(time / 86400);
        returnTime=day + "天";
        if (time % 86400 > 3600) {
          hour = Math.floor(time % 86400 / 3600);
          returnTime+=hour + "时";
          if (time % 86400 % 3600 > 60) {
            minutes = Math.floor(time % 86400 % 3600 / 60);
            returnTime+=minutes + "分";
            seconds = Math.floor(time % 86400 % 3600 % 60);
            returnTime+=seconds + "秒";
          } else {
            seconds = Math.floor(time % 86400 % 3600 % 60);
            returnTime+=seconds + "秒";
          }
        } else {
          if (time % 86400 > 60) {
            minutes = Math.floor(time % 86400 / 60);
            returnTime+=minutes + "分";
            seconds = Math.floor(time % 86400 % 60);
            returnTime+=seconds + "秒";
          } else {
            seconds = Math.floor(time % 86400);
            returnTime+=seconds + "秒";
          }
        }
      }else if(time>=3600&&time<86400){
        let hour = Math.floor(time % 86400 / 3600);
        returnTime=hour + "时";
        if (time % 3600 > 60) {
          minutes = Math.floor(time % 3600 / 60);
          returnTime+=minutes + "分";
          seconds = Math.floor(time % 3600 % 60);
          returnTime+=seconds + "秒";
        } else {
          seconds = Math.floor(time % 3600 % 60);
          returnTime+=seconds + "秒";
        }
      }else if(time>=60&&time<3600){
        minutes = Math.floor(time / 60);
        returnTime=minutes + "分";
        seconds = Math.floor(time % 60);
        returnTime+=seconds + "秒";
      }else if(time){
        returnTime=time + "秒";
      }else{
        returnTime = ""
      }
      return positiveAndNegative==='neg'?"-"+returnTime:returnTime
    },
  },
}
</script>
<style scoped>

</style>