<template>
  <a-modal v-model="modalVisible" :title="modalTitle" :width="1000" :centered="true" :body-style="modalBodyStyle">
    <template slot="footer">
      <a-button type="primary" @click="modalVisible=false">关闭</a-button>
    </template>
    <a-form-model ref="modalForm" layout="inline" class="form-in-twocolumns detail-form-bg" :model="formData" :label-col="{span:9}" :wrapper-col="{span:14}">
      <a-tabs type="card">
        <a-tab-pane key="1" tab="计划详情">
          <h3 style="font-weight: bold; color: #333;">报修详情</h3>
          <a-form-model-item label="计划维修单号" prop="order_num">
            <span>{{formData.order_num}}</span>
          </a-form-model-item>
          <a-form-model-item label="计划来源" prop="order_source">
            <span>{{formData.order_source}}</span>
          </a-form-model-item>
          <a-form-model-item label="报修类别" prop="is_plan_repair">
            <span>{{formData.is_plan_repair==='1'?'计划性维修':'普通维修'}}</span>
          </a-form-model-item>
          <a-form-model-item label="维修类型" prop="repair_matter_type">
            <span>{{formData.repair_matter_type}}</span>
          </a-form-model-item>
          <a-form-model-item label="设备名称" prop="device_name">
            <span>{{formData.device_name}}</span>
          </a-form-model-item>
          <a-form-model-item label="设备编号" prop="device_num">
            <span>{{formData.device_num}}</span>
          </a-form-model-item>
          <a-form-model-item label="设备位置" prop="device_address">
            <span>{{formData.device_address}}</span>
          </a-form-model-item>
          <a-form-model-item label="计划制定时间" prop="create_time">
            <span>{{formData.create_time ? moment(formData.create_time, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''}}</span>
          </a-form-model-item>
          <a-form-model-item label="计划开始时间" prop="need_start_time">
            <span>{{need_start_timeShow}}</span>
          </a-form-model-item>
          <a-form-model-item label="计划完成时间" prop="need_finish_time">
            <span>{{need_finish_timeShow}}</span>
          </a-form-model-item>
          <a-form-model-item label="流程节点" prop="process_node">
            <span>{{repairOrderSteps[formData.process_node]||''}}</span>
          </a-form-model-item>
          <a-form-model-item label="维修内容" prop="content">
            <span>{{formData.content}}</span>
          </a-form-model-item>
          <h3 style="font-weight: bold; color: #333;">派单信息</h3>
          <a-form-model-item label="派单人员" prop="dispatch_person">
            <span>{{formData.dispatch_person}}</span>
          </a-form-model-item>
          <a-form-model-item label="派单时间" prop="register_time">
            <span>{{formData.dispatch_time ? moment(formData.dispatch_time, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''}}</span>
          </a-form-model-item>
          <a-form-model-item label="接单人员" prop="take_person">
            <span>{{formData.take_person}}</span>
          </a-form-model-item>
          <a-form-model-item label="接单时间" prop="take_time">
            <span>{{formData.take_time ? moment(formData.take_time, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''}}</span>
          </a-form-model-item>
          <a-form-model-item label="签到地址" prop="address">
            <span>{{formData.address}}</span>
          </a-form-model-item>
<!--          <a-form-model-item label="到场人员" prop="register_person">-->
<!--            <span>{{formData.register_person}}</span>-->
<!--          </a-form-model-item>-->
          <a-form-model-item label="签到时间" prop="register_time">
            <span>{{formData.register_time ? moment(formData.register_time, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''}}</span>
          </a-form-model-item>
          <a-form-model-item label="接单时长" prop="take_interval">
            <span>{{formData.take_interval?turnHHMMss(formData.take_interval):""}}</span>
          </a-form-model-item>
          <a-form-model-item label="响应时长" prop="register_interval">
            <span>{{formData.register_interval?turnHHMMss(formData.register_interval):""}}</span>
          </a-form-model-item>
          <a-form-model-item label="解困时间" prop="refresh_time">
            <span>{{formData.refresh_time ? moment(formData.refresh_time, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''}}</span>
          </a-form-model-item>
          <a-form-model-item label="解困时长" prop="refresh_interval">
            <span>{{formData.refresh_interval?turnHHMMss(formData.refresh_interval):""}}</span>
          </a-form-model-item>
        </a-tab-pane>
        <a-tab-pane key="2" tab="维修信息">
          <h3 style="font-weight: bold; color: #333;">维修信息</h3>
          <a-form-model-item label="停用时间" prop="deactivate_time">
            <span>{{formData.deactivate_time ? moment(formData.deactivate_time, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''}}</span>
          </a-form-model-item>
          <a-form-model-item label="停用时长" prop="deactivate_interval">
            <span>{{formData.deactivate_interval?turnHHMMss(formData.deactivate_interval):""}}</span>
          </a-form-model-item>
          <a-form-model-item label="修复时间" prop="repair_time">
            <span>{{formData.repair_time ? moment(formData.repair_time, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''}}</span>
          </a-form-model-item>
          <a-form-model-item label="修复时长" prop="repair_interval">
            <span>{{formData.repair_interval?turnHHMMss(formData.repair_interval):""}}</span>
          </a-form-model-item>
<!--          <a-form-model-item label="维修时间" prop="handle_time">-->
<!--            <span>{{formData.handle_time ? moment(formData.handle_time, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''}}</span>-->
<!--          </a-form-model-item>-->
<!--          <a-form-model-item label="维修时长" prop="handle_interval">-->
<!--            <span>{{formData.handle_interval?turnHHMMss(formData.handle_interval):""}}</span>-->
<!--          </a-form-model-item>-->
          <a-form-model-item label="故障代码" prop="fault_code">
            <span>{{formData.fault_code}}</span>
          </a-form-model-item>
          <a-form-model-item label="维修内容" prop="handle_content">
            <span>{{formData.handle_content}}</span>
          </a-form-model-item>
          <a-form-model-item label="更换零部件" prop="replace_parts">
            <span>{{formData.replace_parts}}</span>
          </a-form-model-item>
          <a-form-model-item label="物料/综合费" prop="material_cost">
            <span>{{formData.material_cost}}</span>
          </a-form-model-item>
          <a-form-model-item label="维修状态" prop="handle_status">
            <span>{{formData.handle_status}}</span>
          </a-form-model-item>
          <a-form-model-item label="维修原因" prop="fault">
            <span>{{formData.fault}}</span>
          </a-form-model-item>
          <a-form-model-item label="原因分类" prop="fault_classification">
            <span>{{formData.fault_classification}}</span>
          </a-form-model-item>
          <a-form-model-item label="维修形式" prop="handle_form">
            <span>{{formData.handle_form}}</span>
          </a-form-model-item>
          <a-form-model-item label="维修单位" prop="handle_unit">
            <span>{{formData.handle_unit}}</span>
          </a-form-model-item>
          <a-form-model-item label="延期原因" prop="timeout_reason">
            <span>{{formData.timeout_reason}}</span>
          </a-form-model-item>
          <a-form-model-item label="强关原因" prop="close_reason">
            <span>{{formData.close_reason}}</span>
          </a-form-model-item>
          <h3 style="font-weight: bold; color: #333;">评价信息</h3>
          <a-form-model-item label="物业评价人" prop="appraiser">
            <span>{{formData.appraiser}}</span>
          </a-form-model-item>
          <a-form-model-item label="物业评价等级" prop="grade">
            <span>{{formData.grade}}</span>
          </a-form-model-item>
          <a-form-model-item label="物业评价内容" prop="evaluation">
            <span>{{formData.evaluation}}</span>
          </a-form-model-item>
          <h3 style="font-weight: bold; color: #333;">工单图片</h3>
          <div class="picture-list">
            <div class="picture-line" v-for="(item, index) in cameraList" :key="index">
              <h3>{{item.picTypeName}}：<span v-if="item.time_anomaly==='1'" style="color: red;font-size: small"><span style="font-size: large">时间异常</span> （图片拍摄时间和维修时间相差超过24小时）</span></h3>
              <div v-if="item.pic_type == '16' || item.pic_type == '22'" style="overflow: hidden;padding-bottom: 8px;">
                <video v-for="(cItem, cIndex) in item.list" :key="cIndex" :src="cItem.url" controls="controls"></video>
              </div>
              <a-upload v-else list-type="picture-card" :file-list="item.list" disabled></a-upload>
            </div>
          </div>
        </a-tab-pane>
        <a-tab-pane key="3" tab="报修追踪">
          <a-timeline>
            <a-timeline-item v-for="(item, index) in workFlow" :key="index" :color="'green'">
              <div>操作步骤: {{repairOrderSteps[item.status]}}</div>
              <div>操作人员: {{item.username?item.username+"（"+item.person+"）":item.person}}</div>
              <div>完成时间: {{item.finish_time?moment(item.finish_time,'YYYYMMDDHHmmss').format("YYYY-MM-DD HH:mm:ss"):""}}</div>
            </a-timeline-item>
          </a-timeline>
        </a-tab-pane>
      </a-tabs>
    </a-form-model>
  </a-modal>
</template>
<script>
import moment from 'moment'
import { getWorkflowByOrderId, getRepairsOrderPicByOrderId} from 'A/repairsreport.js'
import {repairOrderStatus, repairOrderSteps, repairsPicType, resultCode} from "@/json/repairsOrderSteps";
import {getRepairsOrderInfoByOrderId} from "A/repairsreport";
export default {
  props: {
    visible: {
      default: false
    },
    detailData: {
      default: null
    }
  },
  data() {
    return {
      moment,
      repairOrderSteps,
      repairsPicType,
      repairOrderStatus,
      resultCode,
      modalVisible: false,
      modalBodyStyle: {
        height: '560px',
        overflowY: 'auto',
      },
      formData: {
        order_id: '',
        parent_order_id: '',
        order_num: '',
        order_source: '',
        order_type: null,
        repair_matter_type: '',
        device_num:'',
        device_name:'',
        device_address:'',
        create_time: '',
        receive_time: '',
        need_start_time: null,
        need_finish_time: null,
        changed_time: null,
        order_status: '',
        process_node: '',
        address: '',

        dispatch_person: '',
        dispatch_time: '',
        take_person: '',
        take_time: '',
        take_interval: '',
        register_person: '',
        register_time: '',
        register_interval: '',
        sign_in_address: '',
        handle_time: '',
        handle_interval: '',
        handle_person: '',
        refresh_time:'',
        refresh_interval:'',
        deactivate_time:'',
        deactivate_interval:'',
        repair_time:'',
        repair_interval:'',
        result_code: '',
        fault: '',
        fault_classification: '',
        fault_code: '',
        order_decs: '',
        result_decs:'',
        is_plan_repair:'',
        plan_repair_num:'',
        content:'',
        replace_parts:'',
        handle_unit:'',
        handle_form:'',
        handle_status:'',
        material_cost:'',
        handle_content:'',
        appraiser:'',
        evaluation:'',
        grade:'',
        owner:'',
        owner_grade:'',
        owner_evaluation:'',
        close_reason:'',
        timeout_reason:'',
      },
      cameraList: [],
      workFlow: [],
      workFlowIndex: 0,
    }
  },
  computed: {
    modalTitle() {
      return '详情'
    },
    create_timeShow() {
      if(this.formData.create_time) {
        return moment(this.formData.create_time, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss')
      }else {
        return '';
      }
    },
    need_start_timeShow() {
      if(this.formData.need_start_time) {
        return moment(this.formData.need_start_time, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss')
      }else {
        return '';
      }
    },
    need_finish_timeShow() {
      if(this.formData.need_finish_time) {
        return this.formData.need_finish_time?moment(this.formData.need_finish_time, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss'):moment(this.formData.need_finish_time, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss')
      }else {
        return '';
      }
    },
  },
  watch: {
    visible(val) {
      if(val) {
        this.modalVisible = true;
        this.initDetail();
        this.getTableData();
        this.getPictureList();
        this.getWorkFlow();
      }
    },
    modalVisible(val) {
      if(!val) {
        this.resetData();
        this.$emit('update:visible', false);
      }
    }
  },
  created() {
    this.modalVisible = this.visible;
  },
  methods: {
    initDetail() {
      if(this.detailData && this.detailData.parent_order_id) {
        this.$nextTick(() => {
          let params = {
            order_id: this.detailData.parent_order_id
          }
          getRepairsOrderInfoByOrderId(params).then(res => {
            if(res && res.returncode == '0') {
              for(let key in this.formData) {
                this.formData[key] = res.item[key]
              }
              this.getWorkFlowIndex();
            }
          })
        })
      }
    },
    resetData() {
      this.formData.parent_order_id = '';
      this.tableData = [];
      this.cameraList = [];
      this.$refs.modalForm.resetFields();
    },
    getWorkFlow() {
      let params = {
        order_id: this.detailData.parent_order_id
      };
      getWorkflowByOrderId(params).then(res => {
        if(res && res.returncode == '0') {
          this.workFlow = res.item;
          this.getWorkFlowIndex();
        }
      })
    },
    getWorkFlowIndex() {
      if(this.formData.process_node && this.workFlow && this.workFlow.length) {
        this.workFlow.forEach((item, index) => {
          if(item.status == this.formData.process_node) {
            this.workFlowIndex = index;
          }
        })
      }
    },
    getTableData() {
      let params = {
        order_id: this.detailData.parent_order_id
      };
    },
    getPictureList() {
      let params = {
        order_id: this.detailData.parent_order_id
      };
      getRepairsOrderPicByOrderId(params).then(res => {
        if(res && res.returncode == '0') {
          let cameraListMap = {};
          res.item.forEach((item, index) => {
            if(!cameraListMap[item.pic_type]) {
              cameraListMap[item.pic_type] = [];
            }
            let obj = {
              ...item,
              uid: 'uid-'+item.pic_type+'-'+index,
              status: 'done',
              url: item.pic_url,
            };
            let nameIndex = item.pic_url.lastIndexOf('/');
            if(nameIndex < 0) {
              nameIndex = item.pic_url.lastIndexOf('\\');
            }
            if(nameIndex > -1) {
              obj.name = item.pic_url.substr(nameIndex+1);
            }
            obj.picTypeName = repairsPicType[item.pic_type] || '';
            cameraListMap[item.pic_type].push(obj);
          })
          for(let key in cameraListMap) {
            this.cameraList.push({
              pic_type: cameraListMap[key][0].pic_type,
              picTypeName: cameraListMap[key][0].picTypeName,
              list: cameraListMap[key],
              time_anomaly:cameraListMap[key][0].time_anomaly
            })
          }
        }
      })
    },
    turnHHMMss(time){
      let hours = Math.floor(time / 3600);
      let minutes = Math.floor((time % 3600) / 60);
      let seconds = Math.round(time % 60);
      return hours + "小时" + minutes + "分钟" + seconds + "秒";
    },
  }
}
</script>
<style lang="scss" scoped>
.picture-list {
  padding-top: 5px;
  .picture-line {
    margin-top: 10px;
    // border-top: solid 1px #e8e8e8;
  }
  video {
    float: left;
    width: 104px;
    margin-right: 8px;
  }
}
</style>